//获取平台的系统应用模块列表【分页/不分页都支持】
const getPlatformAppListUrl = `/gateway/blade-system/mng/platformModelSetting/listPaging`;
//新建/更新平台模块信息
const addPlatformAppListUrl = `/gateway/blade-system/mng/platformModelSetting/edit`;
//删除平台模块信息
const deletePlatformAppListUrl = `/gateway/blade-system/mng/platformModelSetting/delete`;
//租户 拷贝平台配置的模块设置数据（用于自定义配置的第一步生成基础配置数据）
const copyPlatformAppListUrl = `/gateway/blade-system/mng/communityModelSetting/copyPlatformModelSetting`;
//获取所有埋点类型列表【不分页】
const getBuriedPointUrl = `/gateway/blade-system/mng/platformModelSetting/buriedPointTypeList`;
//根据id获取应用模块的详细信息
const getBuriedPointDetailUrl = `/gateway/blade-system/mng/platformModelSetting/detailById`;
//租户列表
const tenantListUrl = `/gateway/blade-system/tenant/select`;
//分类接口
const kindsListUrl = `/gateway/blade-system/mng/platformModelSetting/modelCategoryList`;
export {
  getPlatformAppListUrl,
  addPlatformAppListUrl,
  deletePlatformAppListUrl,
  copyPlatformAppListUrl,
  getBuriedPointUrl,
  getBuriedPointDetailUrl,
  tenantListUrl,
  kindsListUrl,
};
